import React, { useEffect, useState, useContext } from "react";
import SEO from "../components/SEO";
import StickyCarousel from "@src/components/partials/privacy/StickyCarousel";
import CalendlyModal from "@src/components/CalendlyModal";
import { logEvent } from "@src/utils/logClient";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { useStartExperiment } from "@src/utils/experiment";
import { ExternalSwitches } from "@src/utils/experiments/constants";
import { useFeatureFlagsWithOverrides } from "@src/utils/useFeatureFlag";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { AppDataContext } from "@src/components/AppDataContext";
import Letter from "@src/components/partials/privacy/Letter";
import PrivacyHero from "@src/components/partials/privacy/PrivacyHero";
import PrivacyFeatures from "@src/components/partials/privacy/PrivacyFeatures";

const Schools = () => {
  const data = useStaticQuery(graphql`
    query {
      privacyStar: file(relativePath: { eq: "privacy/privacy-star.png" }) {
        publicURL
      }
      signature: file(relativePath: { eq: "privacy/signature.png" }) {
        publicURL
      }
      founders: file(relativePath: { eq: "privacy/founders.jpg" }) {
        publicURL
      }
      directus {
        page_privacy_2024 {
          Cards
          Cards_heading
          Carousel_cards
          Carousel_heading
          Feature_1
          Feature_1_text
          Feature_2
          Feature_2_text
          Hero_heading
          Hero_text
          Letter_heading
          Letter_part_1
          Letter_part_2
          SEO_description
          SEO_title
          Hero_background {
            filename_disk
          }
          Feature_1_image {
            filename_disk
          }
          Feature_2_image {
            filename_disk
          }
        }
      }
    }
  `);

  // const {
  //   directus: {
  //     common_data_schools: {
  //       features,
  //       feedbacks,
  //       traits,
  //       faq_items,
  //       classdojo_help_link_url,
  //       cta_background_image_url,
  //     },
  //     page_schools: {
  //       hero_video_image,
  //       hero_video_url,
  //       hero_button_url,
  //       star_badge_icon,
  //       balloons_badge_image,
  //       resources_image,
  //       resources_webinar_button_url,
  //       resources_button_url,
  //       perks,
  //       perks_check_icon,
  //       cta_button_url,
  //     },
  //   },
  // } = data;

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    logEvent({
      eventName: "teacher.privacy.load_page",
      eventValue: location.href,
      metadata: params,
    });
  }, []);

  const t = useContext(TranslationContext);

  function createTranslatedFaqArray(faqs) {
    const array = {
      title: t.translate("directus.page_schoolleader_2024.FAQs_title"),
      faq: faqs.map((_, index) => ({
        question: t.translate(`directus.page_schoolleader_2024.FAQs.question_${index + 1}`),
        answer: t.translate(`directus.page_schoolleader_2024.FAQs.answer_${index + 1}`),
      })),
    };

    return array;
  }

  // const faqsFromQuery = data.directus.page_schoolleader_2024.FAQs;
  // const translatedFaqArray = createTranslatedFaqArray(faqsFromQuery);

  const translatedCarouselContent = data.directus.page_privacy_2024.Carousel_cards.map((content, index) => ({
    imageSrc: content.image,
    mobileSrc: content.mobile_image,
    heading: t.translate(`directus.page_privacy_2024.Carousel_cards.heading_${index + 1}`),
    paragraph: t.translate(`directus.page_privacy_2024.Carousel_cards.text_${index + 1}`),
  }));

  const SEOComponent = () => (
    <SEO
      noindex={true}
      title="page_titles.privacycenter"
      description="page_descriptions.privacycenter"
      image="https://static.classdojo.com/img/page_privacycenter/privacyCenterFBimage.png"
      twitter={{
        card: "summary_large_image",
        site: "@classdojo",
        creator: "@classdojo",
        title: t.translate("page_titles.privacycenter"),
        description: t.translate("page_descriptions.privacycenter"),
        image: "https://static.classdojo.com/img/page_privacycenter/privacyCenterFBimage.png",
      }}
    />
  );
  return (
    <>
      <SEOComponent />
      <PrivacyHero
        heading={t.translate("directus.page_privacy_2024.Hero_heading")}
        bgImage={`https://static.classdojo.com/uploads/${data.directus.page_privacy_2024.Hero_background.filename_disk}`}
      />
      <Letter
        cards={data.directus.page_privacy_2024.Cards}
        signature={data.signature.publicURL}
        founders={data.founders.publicURL}
      />
      <StickyCarousel content={translatedCarouselContent} image={data.privacyStar.publicURL} />
      <PrivacyFeatures
        feature1={`https://static.classdojo.com/uploads/${data.directus.page_privacy_2024.Feature_1_image.filename_disk}`}
        feature2={`https://static.classdojo.com/uploads/${data.directus.page_privacy_2024.Feature_2_image.filename_disk}`}
      />
    </>
  );
};

export default Schools;
